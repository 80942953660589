import React, { Component, Fragment } from 'react';
import { Container } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import RegisterUser from './RegisterUser';
import RegisterConfirmation from './RegisterConfirmation';

function mapStateToProps(state) {
  return {
    step: state.registerStep,
  };
}

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
    this.props.actions.setRegisterStep(0);
  }

  componentDidUpdate(prevProps){
    if(prevProps.step !== this.props.step ){
      this.setState({step: this.props.step});
    }
  }

  stepContent = () => {
    const { step } = this.state; 
    switch (step) {
      case 0:
        return <RegisterUser />
      case 1:
        return <RegisterConfirmation />
      default:
        console.log('Step missing');
    }
  }

  render() {
    return (
      <Fragment>
        <Container>
          {this.stepContent()}
        </Container>
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
