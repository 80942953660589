import React, { Component } from "react"
import { Avatar, Box, Button, Container, Typography } from "@mui/material"
import { navigate } from "gatsby-link"
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { styled } from '@mui/material/styles';

const PREFIX = "RegisterConfirmation";
const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
}
const RootContainer = styled(Container)(({theme}) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
}));

class RegisterConfirmation extends Component {
  render() {
    return (
      <RootContainer component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOpenIcon />
          </Avatar>
          <Box textAlign="center">
            <Typography component="h1" variant="h5" paragraph>
              Rejestracja
            </Typography>
            <Typography variant='body1' paragraph>
              Na Twój adres e-mail wysłaliśmy wiadomość z linkiem
              aktywacyjnym. Użyj go, aby móc się zalogować.
            </Typography>
          </Box>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => navigate("/user/sign-in")}
          >
            Zaloguj się
          </Button>
        </div>
      </RootContainer>
    )

  }
}

export default RegisterConfirmation;
