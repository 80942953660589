import React from 'react';
import Layout from '../../templates/layout';
import Register from '../../components/user/register/Register';
import Seo from '../../components/utils/Seo';

const RegisterPage = () => (
  <Layout>
    <Register />
  </Layout>
);

export const Head = ({ location }) => (
  <Seo location={location} title="Rejestracja" description='Zarejestruj się już dziś i w ciągu kilku minut zacznij generować płatności dla Twoich klientów'/>
);

export default RegisterPage;
