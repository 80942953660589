import React from 'react';
import {
  Box,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Container,
  Avatar,
  Typography,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from 'gatsby-link';
import FormComponent from '../../containers/FormComponent';
import BaseLoader from '../../utils/BaseLoader';
import FacebookButton from '../../utils/FacebookButton';
import { styled } from '@mui/material/styles';
import { SHOW_FACEBOOK } from '../../../constants';

function mapStateToProps(state) {
  return {
    register: state.register,
  };
}

const PREFIX = 'RegisterUser';
const classes = {
  paper: `${PREFIX}-paper`,
  box: `${PREFIX}-box`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  label: `${PREFIX}-label`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.box}`]: {
    marginTop: theme.spacing(8),
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
  [`& .${classes.label}`]: {
    fontSize: '1em',
  },
}));

class RegisterUser extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      email: '',
      emailError: false,
      emailErrorMessage: '',
      password: '',
      passwordError: false,
      passwordErrorMessage: '',
      passwordRep: '',
      passwordRepError: false,
      passwordRepErrorMessage: '',
      emailDisabled: true,
      passDisabled: true,
      agreement: false,
      regulations: false,
      marketingConsent: false,
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.register !== this.props.register) {
      let register = this.props.register;
      this.validateResponse(register, () => {
        if (register.data) {
          this.props.actions.setRegisterStep(1);
          this.setState({ loading: false });
        }
      });
    }

    if (prevState.email !== this.state.email) {
      let regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.state.email) {
        this.setState({
          emailErrorMessage: 'Podaj email',
          emailDisabled: true,
        });
      } else if (!regExp.test(this.state.email)) {
        this.setState({
          emailError: true,
          emailErrorMessage: 'Nieprawidłowy format email',
          emailDisabled: true,
        });
      } else {
        this.setState({
          emailError: false,
          emailErrorMessage: '',
          emailDisabled: false,
        });
      }
    }

    if (
      prevState.passwordRep !== this.state.passwordRep ||
      prevState.password !== this.state.password
    ) {
      if (this.state.passwordRep !== this.state.password) {
        this.setState({
          passwordRepError: true,
          passwordRepErrorMessage: 'Hasła nie są identyczne',
          passDisabled: true,
        });
      } else {
        this.setState({
          passwordRepError: false,
          passwordRepErrorMessage: '',
          passDisabled: false,
        });
      }
    }
    if (prevState.password !== this.state.password) {
      if (this.state.password.length < 6) {
        this.setState({
          passwordError: true,
          passDisabled: true,
          passwordErrorMessage: 'Hasło jest za krótkie',
        });
      } else {
        this.setState({ passwordError: false, passwordErrorMessage: '' });
      }
    }
  }

  isBtnDisabled = () => {
    const { passDisabled, emailDisabled, agreement, regulations } = this.state;
    if (passDisabled || emailDisabled || !agreement || !regulations) {
      return true;
    }
  };

  callRegister = () => {
    const { email, password, marketingConsent } = this.state;
    const data = {
      email,
      password,
      marketingConsent,
    };
    this.props.actions.register(data);
  };

  render() {
    return (
      <Root>
        <BaseLoader loading={this.state.loading} />
        <Container component='main' maxWidth='xs'>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5' paragraph>
              Rejestracja
            </Typography>
            <Box pb={2} textAlign='center'>
              <Typography variant='body1'>
                Podaj swój email - będziemy go używać do logowania i komunikacji
                z Tobą
              </Typography>
            </Box>
            <form
              onSubmit={this.handleSubmitForm.bind(this, () =>
                this.callRegister()
              )}
            >
              <TextField
                variant='outlined'
                label='Podaj email'
                autoComplete='username'
                id='email'
                name='email'
                type='email'
                placeholder='Email'
                value={this.state.email}
                onChange={this.handleValueChange.bind(this)}
                required
                fullWidth
                inputProps={{ maxLength: 255 }}
                onFocus={this.handleFocus.bind(this)}
                error={this.state.emailError || this.state.formErrorsKeys.email}
                helperText={
                  this.state.emailErrorMessage || this.state.formErrors.email
                }
              />
              <TextField
                variant='outlined'
                autoComplete='new-password'
                id='new-password'
                margin='normal'
                required
                fullWidth
                label='Podaj hasło'
                name='password'
                type='password'
                value={this.state.password}
                onChange={this.handleValueChange.bind(this)}
                error={
                  this.state.passwordError || this.state.formErrorsKeys.password
                }
                helperText={
                  this.state.passwordErrorMessage ||
                  this.state.formErrors.password
                }
              />
              <TextField
                variant='outlined'
                margin='normal'
                autoComplete='new-password'
                id='new-password'
                required
                fullWidth
                name='passwordRep'
                label='Powtórz hasło'
                type='password'
                value={this.state.passwordRep}
                onChange={this.handleValueChange.bind(this)}
                error={this.state.passwordRepError}
                helperText={this.state.passwordRepErrorMessage}
              />

              <Box textAlign={'left'}>
                <br />
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      checked={this.state.agreement}
                      onChange={this.handleCheckboxChange.bind(this)}
                      name='agreement'
                      color='primary'
                    />
                  }
                  label={
                    <>
                      Wyrażam zgodę na przetwarzanie danych osobowych{' '}
                      <Link to='/privacy-policy' className='link-inline'>
                        (Polityka prywatności)
                      </Link>
                    </>
                  }
                />
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      checked={this.state.regulations}
                      onChange={this.handleCheckboxChange.bind(this)}
                      name='regulations'
                      color='primary'
                    />
                  }
                  label={
                    <>
                      Akceptuję treść{' '}
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href='/2020-06-01-FiberPay-Regulamin.pdf'
                        className='link-inline'
                      >
                        regulaminu
                      </a>
                    </>
                  }
                />
                <FormControlLabel
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      checked={this.state.marketingConsent}
                      onChange={this.handleCheckboxChange.bind(this)}
                      name='marketingConsent'
                      color='primary'
                    />
                  }
                  label='Wyrażam zgodę na przekazywanie informacji handlowych.'
                />
              </Box>

              <Button
                fullWidth
                disableElevation
                variant='contained'
                color='primary'
                className={classes.submit}
                disabled={this.isBtnDisabled()}
                type='submit'
              >
                Zarejestruj
              </Button>
            </form>
            {SHOW_FACEBOOK && <Box py={2} style={{ width: '100%' }}>
              <FacebookButton
                type='register'
                content='Zarejestruj przez Facebook'
              />
            </Box>}
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Box mb={4}>
                  Mam już konto.{' '}
                  <Link
                    className='link-inline'
                    to='/user/sign-in'
                    variant='body2'
                  >
                    Zaloguj.
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Root>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
